@font-face {
    font-family: Apercu-Black-Pro;
    src: url('./fonts/Apercu-Black-Pro.ttf');
}

@font-face {
    font-family: Apercu-Bold-Pro;
    src: url('./fonts/Apercu-Bold-Pro.ttf');
}

@font-face {
    font-family: Apercu-Light-Pro;
    src: url('./fonts/Apercu-Light-Pro.ttf');
}

@font-face {
    font-family: Apercu-Regular-Pro;
    src: url('./fonts/Apercu-Regular-Pro.ttf');
}

@font-face {
    font-family: ApercuMono-Light-Pro;
    src: url('./fonts/ApercuMono-Light-Pro.ttf');
}

@font-face {
    font-family: ApercuMono-Regular-Pro;
    src: url('./fonts/ApercuMono-Regular-Pro.ttf');
}

:root {
    --a-primary-color: #8223d2;
    --a-primary-text-color: #000;
    --a-warning-color: #f9d51c;
    --a-warning-text-color: #000;
    --a-error-color: #d91b1b;
    --a-error-text-color: #fff;
    --a-success-color: #0e7b53;
    --a-success-text-color: #fff;
    --primary-font-family: "Apercu-Regular-Pro", sans-serif;
    --secondary-font-family: "Apercu-Light-Pro", sans-serif;

    /* Button CSS variables for themes */
    --button-font-family: var(--primary-font-family);
    /*--button-background-color : #0057B8;
    --button-border-color: #0057B8;
    --button-color: #ffffff;
    --button-background-color--hover: #007AE2;
    --button-border-color--hover : #007AE2;
    --button-color--hover: #ffffff; */

    /* Button Primary Styles */
    /* --button-background-color--primary: transparent;
    --button-border-color--primary: #0057B8;
    --button-color--primary: #0057B8;
    --button-background-color--primary--hover: transparent;
    --button-border-color--primary--hover: #000000;
    --button-color--primary--hover: #000000; */

    /* Button Secondary Styles */
    /* --button-background-color--secondary: #ffffff;
    --button-border-color--secondary: #ffffff;
    --button-color--secondary: #0057B8;
    --button-background-color--secondary--hover: #E7E7E8;
    --button-border-color--secondary--hover: #E7E7E8;
    --button-color--secondary--hover: #0057B8; */

    /* --button-background-color--disabled
    --button-border-color--disabled
    --button-color--disabled */

    /* Button Icon Styles*/
    /* --button-icon-margin-right
    --button-icon-margin-left */

    /* CSS variables for themes */
    /* --icon-margin-right
    --icon-margin-left
    --icon-height
    --icon-vertical-align */

    /* Input Styles*/
    --input-warning-color: var(--a-warning-color);
    --input-error-color: var(--a-error-color);
    --input-success-color: var(--a-success-color);
    --input-font-size: 1em;
    --input-font-family: var(--primary-font-family);
    --input-height: 3em;
    --input-width: initial;
    --input-primary-text-color: var(--a-primary-text-color);
    --input-border-width: 1px;
    --input-border-style: solid;
    --input-border-color: #000;
    --input-border-top-left-radius: 0px;
    --input-border-top-right-radius: 0px;
    --input-border-bottom-right-radius: 0px;
    --input-border-bottom-left-radius: 0px;
    --input-box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.25);
    --input-padding: 14px 8px 10px 12px;
    --input-hover-border-width: 2px;
    --input-hover-border-style: solid;
    --input-hover-border-color: var(--input-border-color);
    --input-focus-border-width: 2px;
    --input-focus-border-style: solid;
    --input-focus-border-color: var(--a-primary-color);
    --input-focus-box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.75);
    --input-disabled-box-shadow: 3px 3px 0px rgba(213, 214, 218, 0.25);
    --input-disabled-text-color: #60676C;
    --input-disabled-background: #e6e6eb;
    --input-disabled-border: #60676C;
    --input-stacked-border-bottom-right-radius: 0px;
    --input-stacked-border-bottom-left-radius: 0px;
}

body {
    font-family: var(--primary-font-family);
    color: var(--a-primary-text-color, #000);
}

a-input[data-focused="true"]+a-helper-text {
    --input-box-shadow: var(--input-focus-box-shadow, 3px 3px 0px rgba(0, 0, 0, 0.75))
}